import "./index.css";

export default function HomePage() {
  return (
    <>
      <div className="home-page">Be patient! I&apos;m working on this...</div>
      <div className="home-page-2">
        Have a look at <a href="/crupest">here</a>!
      </div>
    </>
  );
}
