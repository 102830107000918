import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "./index.css";

import "./i18n";

import App from "./App";

const container = document.getElementById("app");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
